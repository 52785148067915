/**
 *
 * PublicRegister
 *
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';

import Background_login from '../../../images/realestate-back.jpeg';
import Title from 'antd/es/typography/Title';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Steps,
  Tooltip,
} from 'antd';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FaShippingFast } from 'react-icons/fa';
import { FiUserCheck } from 'react-icons/fi';
import { CgSwiss } from 'react-icons/cg';
import { GiHouseKeys } from 'react-icons/gi';
import { AppContext, ContextType } from '../../../engine/contexts/App.context';
import { Helmet } from 'react-helmet';
import SelectFieldAxios from '../../../components/SelectFieldAxios';
import dayjs from 'dayjs';
import { listCantons } from '../../../helpers/utils';
import Logo from '../../../assets/logo_uspi_suisse.svg';

const { Step } = Steps;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default function PublicRegister() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const company = searchParams.get('company');
  const companyId = searchParams.get('companyId');

  const [current, setCurrent] = useState(0);
  const [currentError, setCurrentError] = useState(null);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const [form] = Form.useForm();
  const app = useContext<ContextType>(AppContext);

  const [errorRegister, setErrorRegister] = useState('');
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (errorRegister.length > 0 && errorRegister === 'email') {
      notification.error({
        message: 'Cet email est déja dans notre systéme !',
      });
    }
  }, [errorRegister]);

  const onFinish = async (values) => {
    const data = form.getFieldsValue(true);

    setLoading(true);

    const register = await app.registerNewUser({
      ...data,
    });


    if (register === true) {
      // register worked well
      setRegisterSuccess(true);
    } else {
      setErrorRegister(register);
    }
    setLoading(false);
  };

  const ref = useRef();

  const validateStep = async (step) => {
    let valide = true;
    let error = false;

    switch (step) {
      case 0:
        try {
          let validator = await form.validateFields([
            'email',
            'password',
            'confirm',
          ]);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
      case 1:
        try {
          let validator = await form.validateFields([
            'lastname',
            'firstname',
            'phone',
            'birthdate',
          ]);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
      case 2:
        try {
          let validator = await form.validateFields(['company', 'uspi']);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
    }

    if (!error && step <= 1) {
      next();
    }
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().subtract(18, 'year');
  }

  // GiHouseKeys, FiUserCheck, FaShippingFast
  const steps = [
    {
      title: 'Identifiants',
      icon: <GiHouseKeys />,
      content: (
        <Row className="register-forms" gutter={[10, 10]}>
          <Title level={3}>Quels sont vos identifiants ?</Title>
          <Col span={24}>
            <Form.Item
              normalize={(value) => (value || '').toLowerCase()}
              label={'Votre email'}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Cet email est erroné',
                },
                {
                  required: true,
                  message: 'Merci de renseigner votre email',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label={'Mot de passe'}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre mot de passe !',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={'Confirmer le mot de passe'}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre mot de passe !',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      if (
                        value === undefined ||
                        getFieldValue('password') === undefined
                      ) {
                        return Promise.reject(new Error('Mot de passe vide'));
                      }

                      // if (!/[a-z]/.test(value)) {
                      //   return Promise.reject(
                      //     new Error(
                      //       'Votre mot de passe doit avoir au moins une lettre',
                      //     ),
                      //   );
                      // }

                      if (value.length < 5) {
                        return Promise.reject(
                          new Error(
                            'Votre mot de passe doit faire minimum 5 caractère',
                          ),
                        );
                      }

                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'Les deux mots de passe que vous avez saisis ne correspondent pas !',
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Informations',
      icon: <FiUserCheck />,
      content: (
        <Row className="register-forms" gutter={[10, 10]}>
          <Title level={3}>Quels sont vos informations ?</Title>
          <Col span={24}>
            <Form.Item
              name="lastname"
              label={'Votre nom'}
              style={{ width: '100%' }}
              rules={[
                {
                  required: true,
                  message: 'Merci de renseigner votre nom',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="firstname"
              label={'Votre prénom'}
              style={{ width: '100%' }}
              rules={[
                {
                  required: true,
                  message: 'Merci de renseigner votre prénom',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label={'Votre téléphone'}
              rules={[
                {
                  pattern:
                    /^\+?([1-9][0-9]{0,1})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
                  message: 'Veuillez saisir votre numéro de téléphone !',
                },
              ]}
            >
              <Input
                placeholder={'791234567'}
                style={{ width: '100%' }}
                addonBefore={'+41'}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Gérance',
      icon: <CgSwiss />,
      content: (
        <>
          <Row className="register-forms" gutter={[10, 10]}>
            <Title level={3}>Quelle est votre gérance ?</Title>
            <Col span={24}>
              <SelectFieldAxios
                item={{
                  name: 'company',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: 'Merci de sélectionner une gérance',
                    },
                  ],
                  label: 'Gérance',
                }}
                itemsCallBack={(e) => {
                  // @ts-ignore
                  ref?.current?.setFieldsValue({
                    company: e[0]._id,
                  });
                }}
                endPoint={'/companies'}
                itemCallback={(f) => {
                  return {
                    value: f._id,
                    label: f.name,
                  };
                }}
                className={undefined}
                onSelect={undefined}
                refForm={undefined}
                displayLabel={undefined}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const renderStatusStep = (index) => {
    if (currentError === index) {
      return 'error';
    }

    if (index === current) {
      return 'process';
    }

    if (current > index) {
      return 'finish';
    }

    return 'wait';
  };

  return (
    <div
      id={'registerPage'}
      style={{ backgroundImage: 'url(' + Background_login + ')' }}
    >
      <Helmet>
        <title>Extranet USPI - S'inscrire</title>
        <meta name="description" content="Description of PublicLogin" />
      </Helmet>
      <Card className={'center-vw-full registerBG'}>
        {registerSuccess ? (
          <div style={{ textAlign: 'center', padding: 20 }}>
            <img
              src={Logo}
              style={{
                width: '200px',
                margin: '0 auto',
                display: 'block',
                marginTop: '0px',
              }}
            />
            <br />
            <Title level={2}>Félicitations pour votre inscription ! 🎉</Title>
            <br />
            <p style={{ fontSize: '20px' }}>
              Nous sommes ravis de vous accueillir en tant que membre USPI. Un
              email de confirmation vous a été envoyé à votre adresse. Notre
              équipe validera votre compte afin afin d'accéder à notre espace
              réservé et profiter de tous nos services.
              <br /> <br />
              Nous mettons tout en œuvre pour rendre votre expérience
              exceptionnelle. Si vous avez des questions ou besoin d'assistance,
              n'hésitez pas à nous contacter. Notre équipe se tient à votre
              disposition.
              <br />
              Merci de votre confiance et bienvenue chez nous !
              <br />
              <br />
              info@uspi.ch
              <br />
              +41 79 123 45 96
              <br />
            </p>
            <Link to={'/auth'}>
              <Button
                style={{ width: '100%' }}
                type={'primary'}
                size={'middle'}
              >
                Retour
              </Button>
            </Link>
          </div>
        ) : (
          <Form
            ref={ref}
            tabIndex={0}
            {...formItemLayout}
            form={form}
            layout={'vertical'}
            name="register"
            onFinish={onFinish}
          >
            <Steps
              current={current}
              type="navigation"
              responsive
              className="site-navigation-steps"
            >
              {steps.map((item, k) => (
                <Step
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  status={renderStatusStep(k)}
                />
              ))}
            </Steps>

            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && (
                <Button
                  size={'small'}
                  style={{ width: '50%' }}
                  onClick={() => prev()}
                >
                  Précédent
                </Button>
              )}
              {current < steps.length - 1 && current !== 0 ? (
                <Button
                  type="primary"
                  size={'small'}
                  onClick={() => validateStep(current)}
                  style={{ width: 'calc(50% - 15px)', marginLeft: '10px' }}
                >
                  Prochaine étape
                </Button>
              ) : null}
              {current === 0 && (
                <Button
                  type="primary"
                  size={'small'}
                  onClick={() => validateStep(current)}
                  style={{ width: '100%' }}
                >
                  Prochaine étape
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  //disabled={loading}
                  size={'small'}
                  // loading={loading}
                  style={{ width: 'calc(50% - 15px)', marginLeft: '10px' }}
                  onClick={() => validateStep(current)}
                >
                  Créer mon compte
                </Button>
              )}
            </div>
            <p style={{ color: '#ff4d4f' }}>
              {errorRegister === 'email' &&
                'Cet email est déja dans notre systéme !'}
            </p>
            <Col span={12}>
              <Link to={'/'}>
                <p>Je possède un compte</p>
              </Link>
            </Col>
          </Form>
        )}
      </Card>
    </div>
  );
}
