// @ts-nocheck

import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  CollapsePanelProps,
  Input,
  Layout,
  Popover,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  theme,
  Typography,
} from 'antd';
import { Helmet } from 'react-helmet';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import { useParams } from 'react-router-dom';
import {
  clearCahierModel,
  getCahierModel,
  getCahierEntries,
  updateCahierModel,
  updateEntryValue,
  updatePrestation,
  updateCahier,
  updatePrestationsShown,
  updateChapterShwon,
} from './CahierModel.slice';
import { FilePdfOutlined, SaveFilled } from '@ant-design/icons';
import CustomForm from '../../components/CustomForm';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import { CahierSubChapterPanel } from './CahierSubChapter.panel';
import { StickyWrapper } from '../../helpers/utils';
import useCahierPDF from './Cahier.pdf';
import { LoadingIndex } from '../../layouts/LoadingIndex';

const { Panel } = Collapse;
const { Content, Sider, Header, Footer } = Layout;

export function CahierModelDetail({ isModel }) {
  const {} = useContext<ContextType>(AppContext);

  const { cahierModelData, loadingSave, cahier, chaptersHidden } =
    useAppSelector((state) => state.cahierModel);

  const [toggleLevel, setToggleLevel] = useState([]);

  const [numerotationAutomatic, setNumerotationAutomatic] = useState(
    cahier?.numerotationAutomatic,
  );

  useEffect(() => {
    if (
      numerotationAutomatic === undefined &&
      cahier?.numerotationAutomatic !== undefined
    ) {
      setNumerotationAutomatic(cahier?.numerotationAutomatic);
    }
  }, [cahier?.numerotationAutomatic]);


  const { name } = cahierModelData || {};

  const { getCahier, loadingPdf } = useCahierPDF();

  const { user } = useContext<ContextType>(AppContext);

  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const formRef = useRef(null);

  const { id: cahierModelId, cahier: cahierId = false } = useParams();

  useEffect(() => {
    dispatch(getCahierModel(cahierModelId));

    if (cahierId !== false && !isModel) {
      dispatch(getCahierEntries(cahierId));
    }

    return () => {
      dispatch(clearCahierModel());
    };
  }, []);

  const [loadingSvg, setLoadingSvg] = useState(false);
  const [search, setSearch] = useState('');

  function flattenAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function searchFilterEntries(json, searchString) {
    const flattenedSearchStr = flattenAccents(searchString);

    const matchStringSearch = (string, searching) => {
      return (
        flattenAccents(searching)
          .toLowerCase()
          .includes(string.toLowerCase()) ||
        searching.includes(string.toUpperCase())
      );
    };

    const filteredCategories = json.categories
      .map((category) => {
        const filteredChapters = category.chapters
          .map((chapter) => {
            const filteredSubChapters = chapter.subChapters
              .map((subChapter) => {
                const filteredEntries = subChapter.entries.filter((entry) => {
                  let flattenedEntryName = flattenAccents(entry.name);

                  if (numerotationAutomatic === false) {
                    flattenedEntryName = entry.n + ' ' + flattenedEntryName;
                  }

                  return matchStringSearch(
                    flattenedSearchStr,
                    flattenedEntryName,
                  );
                });
                return { ...subChapter, entries: filteredEntries };
              })
              .filter(
                (subChapter) =>
                  subChapter.entries.length > 0 ||
                  matchStringSearch(
                    flattenedSearchStr,
                    numerotationAutomatic
                      ? subChapter.name
                      : `${subChapter.n} ${subChapter.name}`,
                  ),
              );
            return { ...chapter, subChapters: filteredSubChapters };
          })
          .filter(
            (chapter) =>
              chapter.subChapters.length > 0 ||
              matchStringSearch(
                flattenedSearchStr,
                numerotationAutomatic
                  ? chapter.name
                  : `${chapter.n} ${chapter.name}`,
              ),
          );
        return { ...category, chapters: filteredChapters };
      })
      .filter((category) => category.chapters.length > 0);

    return {
      ...json,
      categories: filteredCategories,
    };
  }

  const onSubmitForm = (data) => {
    if (isModel) {
      dispatch(updateCahierModel({ ...cahierModelData, name: data.name }));
    } else {
      dispatch(updateCahier({ ...cahier, ...data }));
    }
  };

  const inputs = [
    {
      parentLabel: 'Informations',
      children: [
        {
          label: 'Nom du document',
          name: 'name',
          value: isModel ? name : cahier?.name,
          required: true,
          disabled: loadingPdf,
          capitalize: false,
          type: 'input',
          col: 6,
          offset: 0,
        },
        !isModel
          ? {
              label: 'Description du cahier (en tête du document)',
              name: 'description',
              disabled: loadingPdf,
              value: cahier?.description,
              type: 'textarea',
              col: 8,
              offset: 0,
            }
          : {},
        !isModel
          ? {
              label: 'Afficher la colonne "Type" dans le PDF',
              name: 'showType',
              disabled: loadingPdf,
              value: cahier?.showType,
              type: 'switch',
              col: 5,
              offset: 0,
            }
          : {},
        !isModel
          ? {
              label: (
                <p>
                  Activer la numérotation automatique <br />
                  Cela recalculera toute la numérotation du cahier (conseillé).
                  Laissez décoché si vous souhaiter utiliser l'ancienne
                  numérotation sans recalcule de la numérotation
                </p>
              ),
              disabled: loadingPdf,
              name: 'numerotationAutomatic',
              value: cahier?.numerotationAutomatic,
              type: 'switch',
              yes: 'Automatique',
              no: 'Fixe',
              col: 5,
              offset: 0,
            }
          : {},
      ],
    },
  ];

  const toggleChapter = (e, chapterId) => {
    e.stopPropagation();

    dispatch(
      updateChapterShwon({
        shown: e.target.checked,
        chapterId,
      }),
    );
  };

  const isChapterHidden = (chapterId) => {
    if (cahier !== null) {
      return cahier?.chaptersHidden?.some((e) => e === chapterId);
    }
  };

  const renderCahierContent = () => {
    return (
      <Collapse
        style={{ width: '100%' }}
        className={'mainCollaps'}
        defaultActiveKey={[cahierModelData.categories.map((o) => o._id)]}
      >
        {searchFilterEntries(cahierModelData, search).categories.map(
          (category, catIndex) => {
            const level = catIndex + 1;
            return (
              <Panel
                extra={<></>}
                header={
                  <span style={{ fontWeight: 700 }}>
                    {numerotationAutomatic
                      ? `${level}. ${category.name}`
                      : `${category.n}. ${category.name}`}
                  </span>
                }
                key={category._id}
              >
                <Collapse style={{ width: '100%' }} className={'you'}>
                  {category.chapters.map((chapter, indexChapter) => {
                    let levelChapter = indexChapter + 1;
                    const chapterIsOpen =
                      toggleLevel.find((e) => e === chapter._id) === undefined;
                    return (
                      <Panel
                        // extra={
                        //   <Button
                        //     type={'default'}
                        //     size={'small'}
                        //     onClick={(e) => {
                        //       e.preventDefault();
                        //       e.stopPropagation();
                        //       onClickToggleButton(chapter._id);
                        //     }}
                        //   >
                        //     {chapterIsOpen ? ' Tout ouvrir' : ' Tout fermer'}
                        //   </Button>
                        // }
                        header={
                          <span
                            style={{
                              opacity: isModel
                                ? 1
                                : !isChapterHidden(chapter._id)
                                ? 1
                                : 0.5,
                            }}
                          >
                            {!isModel && (
                              <Checkbox
                                style={{ marginRight: 10 }}
                                checked={!isChapterHidden(chapter._id)}
                                onClick={(e) => {
                                  toggleChapter(e, chapter._id);
                                }}
                              />
                            )}
                            {numerotationAutomatic
                              ? `${level}.${levelChapter}. ${chapter.name}`
                              : `${chapter.n} ${chapter.name}`}
                          </span>
                        }
                        key={chapter._id}
                      >
                        <Collapse
                          style={{ width: '100%' }}
                          bordered={false}
                          defaultActiveKey={chapter.subChapters.map(
                            (e) => e._id,
                          )}
                        >
                          {chapter.entries.length > 0 ? (
                            <CahierSubChapterPanel // Special use case relou
                              chapterHidden={isChapterHidden(chapter._id)}
                              chapter={chapter}
                              numerotationAutomatic={numerotationAutomatic}
                              category={category}
                              key={chapter._id}
                              indexChapter={indexChapter}
                              catIndex={catIndex}
                              level={level}
                              levelChapter={levelChapter}
                              levelSubChapter={levelChapter}
                              subChapter={chapter}
                              indexSubChapter={indexChapter}
                              isModel={isModel}
                            />
                          ) : (
                            chapter.subChapters.map(
                              (subChapter, indexSubChapter) => {
                                const levelSubChapter = indexSubChapter + 1;

                                type PanelWrapperProps = {
                                  // your panel wrapper props
                                } & CollapsePanelProps;

                                return (
                                  <CahierSubChapterPanel
                                    chapterHidden={isChapterHidden(chapter._id)}
                                    numerotationAutomatic={
                                      numerotationAutomatic
                                    }
                                    chapter={chapter}
                                    category={category}
                                    key={subChapter._id}
                                    indexChapter={indexChapter}
                                    catIndex={catIndex}
                                    level={level}
                                    levelChapter={levelChapter}
                                    levelSubChapter={levelSubChapter}
                                    subChapter={subChapter}
                                    indexSubChapter={indexSubChapter}
                                    isModel={isModel}
                                  />
                                );
                              },
                            )
                          )}
                        </Collapse>
                      </Panel>
                    );
                  })}
                </Collapse>
              </Panel>
            );
          },
        )}
      </Collapse>
    );
  };

  const onValuesChange = (changed, all) => {
    if (changed['numerotationAutomatic'] !== undefined) {
      setNumerotationAutomatic(changed.numerotationAutomatic);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Extranet USPI - Cahier</title>
        <meta name="description" content="Client détail" />
      </Helmet>
      <StickyWrapper>
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 0,
              marginLeft: '20px',
              color: ' rgb(0 21 41)',
              fontWeight: 300,
              fontSize: '25px',
            }}
            className={''}
          >
            {cahierModelData === null ? (
              <Skeleton.Input active />
            ) : isModel ? (
              `Model:  ${name} `
            ) : (
              `Cahier:  ${cahier?.name} `
            )}
          </Typography.Title>
          <Row gutter={[15, 15]}>
            <Col span={24}>
              <Space>
                {/*<Link to={'/admin/clients'}>*/}
                {/*  <Button type={'primary'} icon={<LeftOutlined />}>*/}
                {/*    Retour à la liste*/}
                {/*  </Button>*/}
                {/*</Link>*/}
                <Button
                  disabled={cahierModelData === null || loadingSave}
                  loading={loadingSave}
                  type="primary"
                  icon={<SaveFilled />}
                  onClick={() => {
                    formRef.current.submit();
                  }}
                >
                  Sauvegarder
                </Button>
                {user?.company ? (
                  <Button
                    type={'primary'}
                    size={'middle'}
                    disabled={loadingPdf} // @ts-ignore
                    onClick={() => getCahier(cahier.template, cahier._id)}
                    value="small"
                  >
                    <FilePdfOutlined />
                    Télécharger le PDF
                  </Button>
                ) : (
                  <Popover
                    content={"Vous n'êtes pas lié à une gérance"}
                    title={`Fonctionnalité non disponible`}
                    trigger="hover"
                  >
                    <Button disabled={true} size={'small'}>
                      <FilePdfOutlined />
                      PDF
                    </Button>
                  </Popover>
                )}
              </Space>
            </Col>
          </Row>
        </Header>
      </StickyWrapper>

      <Content
        style={{
          margin: '20px',
          overflow: 'initial',
        }}
      >
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Card>
              {cahierModelData === null ? (
                <Skeleton />
              ) : (
                <CustomForm
                  handleSubmit={onSubmitForm}
                  dataSource={inputs}
                  refForm={formRef}
                  onValuesChange={onValuesChange}
                  labelCol={24}
                  wrapperCol={24}
                />
              )}
              <label>
                <span style={{ marginBottom: 10, display: 'inline-block' }}>
                  Recherche
                </span>
                <Input
                  disabled={loadingPdf}
                  placeholder={'Rechercher une entrée...'}
                  value={search}
                  onChange={(v) => setSearch(v.target.value)}
                />
              </label>
            </Card>
          </Col>
          <Col span={24}>
            {cahierModelData === null || loadingPdf ? (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '30vh', // set height to 100vh for full viewport height
                  display: 'flex',
                  justifyContent: 'center', // center horizontally
                  alignItems: 'center', // center vertically
                }}
              >
                <Spin size={'large'} />
              </div>
            ) : (
              renderCahierContent()
            )}
          </Col>
        </Row>
      </Content>
    </div>
  );
}
